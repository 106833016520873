<template>
  <v-list-item class="ps-0 ps-md-8 pr-0">
    <div class="d-flex" :class="{ 'pl-md-7': !item.publicAccess }">
      <v-tooltip v-if="item.publicAccess" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mr-2 mt-1" color="primary" v-bind="attrs" v-on="on">
            mdi-check-decagram
          </v-icon>
        </template>
        <span>{{ $t('services.tooltip_public_category') }}</span>
      </v-tooltip>
      <div>
        <p class="text-h6 ma-0">{{ item.name }}</p>
        <p class="text-caption ma-0">{{ $t('service.order_sort') }}: {{ item.order }}</p>
      </div>
    </div>
    <v-spacer />
    <v-btn
      v-if="canUpdate"
      class="mr-0 text-caption text-body-2 text-uppercase"
      :class="{ 'services__edit-category': !media.isMobile }"
      :to="editCategoryLink"
      :text="!media.isMobile"
      small
      :icon="media.isMobile"
      @click.stop
    >
      <v-icon v-if="media.isMobile" color="primary">
        mdi-pencil
      </v-icon>
      <span v-else>{{ $t('services.button_edit_category') }}</span>
    </v-btn>
  </v-list-item>
</template>

<script>
import { SERVICES_CATEGORY_EDIT } from '@/constants/routes';

export default {
  name: 'Category',

  inject: ['media'],

  props: {
    item: {
      type: Object,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    editCategoryLink() {
      return {
        name: SERVICES_CATEGORY_EDIT,
        params: { id: this.item.id },
        query: { prevPage: this.page },
      };
    },
  },
};
</script>

<style lang="scss">
.services-category {
  min-height: 64px;
}
</style>
